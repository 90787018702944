<template>
    <v-container fluid fill-height>
        <v-layout wrap           
            align-center
            justify-center
        >
            <v-flex
                xs10
                sm4
                md3
            >
                <v-form v-on:submit.prevent="submit">
                    <v-flex>
                        <v-text-field
                            filled
                            v-model="username"
                            append-icon=" "
                            label="Nombre de Usuario"
                            required
                            autofocus
                            v-validate="'required'"
                            data-vv-name="username"
                            data-vv-as="Nombre de Usuario"
                            :error-messages="errors.collect('username')"
                        ></v-text-field>
                    </v-flex>

                    <v-flex >
                        <v-text-field
                            filled
                            v-model="password"
                            :append-icon="showPassword ? 'mdi-eye-off' : 'mdi-eye'"
                            :type="showPassword ? 'text' : 'password'"
                            name="input-10-1"
                            label="Clave de Ingreso"
                            counter
                            @click:append="showPassword = !showPassword"
                            v-validate="'required'"
                            data-vv-name="password"
                            data-vv-as="Clave de Ingreso"
                            :error-messages="errors.collect('password')"
                        ></v-text-field>
                    </v-flex>

                    <v-flex  align-self-center class="mt-4">
                        <v-btn
                            type="submit"
                            color="primary"
                            block
                            :loading="loading"
                        >
                            INICIAR SESIÓN
                        </v-btn>
                        <v-dialog
                          v-model="recoverUserDialog"
                          persistent
                          max-width="600px"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                color="grey darken-1"
                                text
                                block
                                v-bind="attrs"
                                v-on="on"
                            >
                                Olvidé mi contraseña
                            </v-btn>
                          </template>
                          <v-card>
                            <v-card-title>
                              <span class="text-h5">Recuperar Contraseña</span>
                            </v-card-title>
                            <v-card-text>
                              <v-container>
                                <v-form
                                    ref="recoverUserForm"
                                    data-vv-scope="recover"
                                >
                                  <v-row>
                                    <v-col cols="12">
                                        <v-text-field
                                            ref="recoverEmail"
                                            v-model="recoverEmail"
                                            label="Dirección de E-mail"
                                            outlined
                                            v-validate="'required|email'"
                                            data-vv-name="email"
                                            data-vv-as="Dirección de E-mail"
                                            :error-messages="errors.collect('recover.email')"
                                            required
                                            autocomplete="off"
                                            @keyup.enter="recoverUser"
                                        ></v-text-field>
                                    </v-col>
                                  </v-row>
                                </v-form>
                              </v-container>
                            </v-card-text>
                            <v-card-actions>
                              <v-spacer></v-spacer>
                              <v-btn
                                text
                                @click="recoverUserDialog = false"
                              >
                                Cerrar
                              </v-btn>
                              <v-btn
                                color="blue darken-1"
                                text
                                @click="recoverUser"
                              >
                                Recuperar contraseña
                              </v-btn>
                            </v-card-actions>
                          </v-card>
                        </v-dialog>
                    </v-flex>
                </v-form>
             </v-flex>
        </v-layout>
    </v-container>
</template>

<script>

export default {
  data: () => ({
    loading: false,
    showPassword: false,
    username: '',
    password: '',
    recoverUserDialog: false,
    recoverEmail: '',
  }),

  watch: {
    recoverUserDialog (val) {
        if (!val) {
            this.recoverEmail = ''
            this.$refs.recoverEmail.reset()
            this.$refs.recoverUserForm.reset()
            this.$validator.reset()
        }
    }
  },

  methods: {
    submit: function () {
      this.$validator.validateAll()
        .then(result => {
          if (!result) return;

          this.login()
        });
    },
    login: function () {
        this.loading = true

        let credentials = {
          username: this.username,
          password: this.password
        };

        this.$http.post('login_check', credentials, null, 'json')
            .then((response) => {
                sessionStorage.setItem('cupones-token', response.data.token)
                this.$store.state.loggedIn = true
                this.$router.push({name: 'mis-cupones'}, () => {})
            })
            .catch((error) => {
              const message = error.response.data.message || 'Disculpe, ocurrío un error al procesar su solicitud'

              this.$eventHub.$emit('snackbar-message', message, 'error')
            })
            .then(() => {
                this.loading = false;
            })
    },

    recoverUser: function () {
        this.$validator.validateAll('recover').then(result => {
            if (!result) return;

            var credentials = {
                email: this.recoverEmail,
            };

            this.$http.post('json-recuperar-credenciales', credentials, null, 'json')
                .then((response) => {
                    this.$eventHub.$emit('snackbar-message', response.data.message)
                    this.recoverUserDialog = false
                })
                .catch((error) => {
                    switch (error.response.status) {
                        case 400:
                            this.$eventHub.$emit('snackbar-message', 'Los datos no son válidos', 'error')

                            break;
                        case 401:
                            break;
                        case 422:
                            this.$eventHub.$emit('snackbar-message', error.response.data?.message || 'Disculpe, no se pudo procesar su solicitud', 'error')

                            break;
                        default:
                            this.$eventHub.$emit('snackbar-message', 'Disculpe, ocurrío un error al procesar su solicitud', 'error')
                    }
                })
                .then(() => {
                    this.loading = false;
                })
        });
    },
  },
}
</script>

<style>
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    border: 0px solid #fff;
    -webkit-box-shadow: 0 0 0px 1000px transparent inset;
    transition: background-color 5000s ease-in-out 0s;

  }
</style>

